import { RouteConfig } from 'vue-router';
import Dashboard from '@/views/dashboard/dashboard.vue';
import User from '@/views/users/users.vue';
import Order from '@/views/orders/orders.vue';

const dashboardRoutes: Array<RouteConfig> = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            layout: 'dashboard-layout',
            requiresAuth: true
        }
    },

    {
        path: '/users',
        name: 'Users',
        component: User,
        meta: {
            layout: 'dashboard-layout',
            requiresAuth: true
        }
    },
    {
        path: '/orders',
        name: 'Orders',
        component: Order,
        meta: {
            layout: 'dashboard-layout',
            requiresAuth: true
        }
    },
];

export default dashboardRoutes;