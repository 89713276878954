
























































import { fetchStats, getStats } from '@/store/dashboardState';
import { fetchUsers, getUser, getWhiteLabelUsers } from '@/store/userState';
import { formatDateTime } from '@/utils/dayjs';
import {Vue, Component} from 'vue-property-decorator';

@Component
export default class Dashboard extends Vue {

    get stats() {
        return getStats(this.$store);
    }

    get users() {
        const users = getUser(this.$store);
        if (users.length > 0) {
        users.sort((a, b) => {
            const aTimeValue = new Date(a.createdAt).getTime();
            const bTimeValue = new Date(b.createdAt).getTime();
            if (aTimeValue > bTimeValue) {
            return -1;
            }
            if (bTimeValue > aTimeValue) {
            return 1
            }
            return 0;
        })
        }
        return users.slice(0, 5);
    }

    get whitelabelUsers() {
        return getWhiteLabelUsers(this.$store).length;
    }

    modify(key: any) {
        return key.charAt(0).toUpperCase() + key.slice(1);
    }

    mounted() {
        this.$toast.info('Fetching the numbers');
        fetchStats(this.$store);
        fetchUsers(this.$store);
    }

     formatDate(date: string){
        return formatDateTime(date);
    }
}
