export const userRoles = [
    {
        title: 'Frontend',
        description: 'Qrdaddy'
    },

    {
        title: 'OTO 1',
        description: 'Super saver pass'
    },

    {
        title: 'OTO 2',
        description: 'Unlimited'
    },

    {
        title: 'OTO 3',
        description: 'QR Daddy Agency'
    },

    {
        title: 'OTO 4',
        description: 'Template Club'
    },

    {
        title: 'OTO 5',
        description: 'AIWA'
    },

    {
        title: 'OTO 6',
        description: 'Whitelabel license'
    }
]