import { ApplicationState } from "./";
import { ActionContext } from "vuex";
import { getStoreAccessors } from "vuex-typescript";
import { initServer, SERVER } from "@/utils/server";
type DashboardContext = ActionContext<DashboardState, ApplicationState>

export interface DashboardState {
    statistics: any
}

const initialState: DashboardState = {
    statistics: {}
}

export const dashboardState = {	
     namespaced: true, 
     state: initialState,
     getters: {
         getStats(state: DashboardState) {
             return state.statistics;
         }
     },
     mutations: {
         setStatistics(state: DashboardState, data: any) {
             state.statistics = data;
         } 
     },
     actions: {
         fetchStats(context: DashboardContext) {
            return new Promise((resolve, reject) => {
                initServer(SERVER)
                const request = SERVER.get('admin/stats')
                
                request.then((response) => {
                    const apiResponse= response.data;
                    if(apiResponse.success){
                        context.commit('setStatistics', apiResponse.data);
                        resolve('done')
                    } else {
                        reject(apiResponse.message)
                    }
                }).catch((err) => {
                    reject('err')
                })
            });
         }
     },
}

const { read, dispatch } = getStoreAccessors<DashboardState, ApplicationState>('dashboard');

const getters = dashboardState.getters;
const actions = dashboardState.actions;

export const getStats = read(getters.getStats);

export const fetchStats = dispatch(actions.fetchStats);