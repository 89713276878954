








import { Component } from "vue-property-decorator";
import Base from './Base'

@Component
export default class App extends Base {}
