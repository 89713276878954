import { ApplicationState } from "./";
import { ActionContext } from "vuex";
import { getStoreAccessors } from "vuex-typescript";
import { initServer, SERVER } from "@/utils/server";
type OrdersContext = ActionContext<OrdersState, ApplicationState>

export interface OrdersState {
    orders: any[];
}

const initialState: OrdersState = {
    orders: []
}

export const ordersState = {
    namespaced: true,
    state: initialState,
    getters: {
        getOrders(state: OrdersState) {
            return state.orders;
        }
    },
    mutations: {
        setOrders(state: OrdersState, orders: any[]) {
            state.orders = orders;
        }
    },
    actions: {
        async getOrders(context: OrdersContext) {
            return new Promise((resolve, reject) => { 
                const request = SERVER.get('/orders');
                request.then((data) => {
                    context.commit('setOrders', data.data.data);
                })
             })
        }
    },
}

const { read, dispatch } = getStoreAccessors<OrdersState, ApplicationState>('orders');

const getters = ordersState.getters;
const actions = ordersState.actions;

export const getOrders = read(getters.getOrders);
export const fetchOrders = dispatch(actions.getOrders);