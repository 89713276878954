import Vue from 'vue'
import App from './App.vue'

import 'jquery'
import 'bootstrap'
import '@popperjs/core'
import 'bootstrap/dist/css/bootstrap.css';

// import './assets/css/app.css'


import '@/scss/style.scss';

import { createStore } from './store';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import AppLayout from './layouts/app-layout.vue'

import { ConfigureRouteGuard } from './router';
// import { configureModerator } from './store/storeModerator'

Vue.config.productionTip = false

const store = createStore();
const router = ConfigureRouteGuard(store);

// configureModerator(store);

Vue.use(VueToast);
Vue.component('AppLayout', AppLayout)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
