































import { fetchOrders, getOrders } from '@/store/ordersState';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Orders extends Vue {

    searchKey = '';
    get orders() {
        return getOrders(this.$store);
    }

    mounted() {
        fetchOrders(this.$store);
    }

    getUserEmail(order: any) {
        // if(order.jvzooPayload.ccustemail == 'jlkinteractive@gmail.com'){
        //     console.log(order.jvzooPayload);
        // }
        return order.jvzooPayload.ccustemail;
    }
}
