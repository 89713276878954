



















































import { fetchUsers, getUser, resetPassword, deleteUser } from '@/store/userState';
import { formatDateTime } from '@/utils/dayjs';
import { Vue, Component } from 'vue-property-decorator';
import NewUserModal from './addUser.vue';
import { Modal } from 'bootstrap';
import DeleteUserModal from './deleteUserModal.vue';
import EditUserModal from './editUserModal.vue';
import ResetPasswordModal from './resetPasswordModal.vue';


@Component({ components: { NewUserModal, ResetPasswordModal, DeleteUserModal, EditUserModal } })
export default class User extends Vue {

  selectedUser: any | null = null;
  searchKey = '';

  get users() {
    const users = getUser(this.$store);
    if (users.length > 0) {
      users.sort((a, b) => {
        const aTimeValue = new Date(a.createdAt).getTime();
        const bTimeValue = new Date(b.createdAt).getTime();
        if (aTimeValue > bTimeValue) {
          return -1;
        }
        if (bTimeValue > aTimeValue) {
          return 1
        }
        return 0;
      })
    }
    if (this.searchKey.length > 0) {
      return users.filter(user => {
        if (user.firstName != null && user.email != null) {
          return user.firstName.toLowerCase().includes(this.searchKey.toLowerCase()) || user.email.toLowerCase().includes(this.searchKey.toLowerCase());
        }
      })
    }
    return users;
  }

  mounted() {
    this.$toast.info('Fetching the users');
    fetchUsers(this.$store);
  }

  formatDate(date: string) {
    return formatDateTime(date);
  }

  openModal() {
    new Modal(document.getElementById('newUserModal')!).show();
  }

  openDeleteModal(user: any) {
    this.selectedUser = user;
    new Modal(document.getElementById('deleteUserModal')!).show();
  }

  openEditModal(user: any) {
    this.selectedUser = user;
    new Modal(document.getElementById('editUserModal')!).show();
  }

  openResetPasswordModal(user: any) {
    this.selectedUser = user;
    new Modal(document.getElementById('resetPasswordModal')!).show();
  }

  deleteUser() {
    deleteUser(this.$store, { userId: this.selectedUser.id })
      .then(() => this.$toast.success('User deleted'))
      .catch(() => this.$toast.error('User not deleted'));
  }

  resetPassword() {
    resetPassword(this.$store, { email: this.selectedUser.email })
      .then(() => this.$toast.success('User Password reset'))
      .catch(() => this.$toast.error('User Password not reset'));
  }
}
