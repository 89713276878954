import Vue from 'vue';
import Vuex from 'vuex';
import { authState } from './authState';
import { dashboardState } from './dashboardState';
import { userState } from './userState';
import { ordersState } from './ordersState';

export interface ApplicationState {}

Vue.use(Vuex)

export const createStore = () => new Vuex.Store<ApplicationState>({
  modules: {
    auth: authState,
    dashboard: dashboardState,
    user: userState,
    orders: ordersState,
  }
});
