






















import {Vue, Component} from 'vue-property-decorator';
import { login } from '@/store/authState';

@Component
export default class Login extends Vue {
    email = '';
    password = '';


    login() {
        if(this.validate()){
            let payload: any = { email: this.email, password: this.password }
            
            login(this.$store, payload)
                .then(() => {
                    this.$router.push({name: 'Dashboard'})
                }).catch((e) => {
                    this.$toast.error(e);
                })
        }
    }

    validate() {
        if(this.email.length == 0){
            this.$toast.error('Enter your email');
            return false;
        }

        if(this.password.length == 0){
            this.$toast.error('Enter your password');
            return false;
        }

        return true;
    }
}
