import { ApplicationState } from "./";
import { ActionContext } from "vuex";
import { getStoreAccessors } from "vuex-typescript";
import { initServer, SERVER } from "@/utils/server";
type UserContext = ActionContext<UserState, ApplicationState>

export interface UserState {
    users: any[]
}

const initialState: UserState = {
    users: []
}

export const userState = {	
     namespaced: true, 
     state: initialState,
     getters: {
         getUser(state: UserState) {
             return state.users;
         },

         getWhiteLabelUsers(state: UserState) {
             return state.users.filter(user=> user.isWhiteLabel);
         }
     },
     mutations: {
        setUsers(state: UserState, users: any[]) {
            state.users = users;
        },

        appendUser(state: UserState, user: any) {
            state.users.push(user);
        },

        updateUser(state: UserState, user: any) {
            const userIndex = state.users.findIndex(usr => user.id == usr.id);
            if(userIndex > -1){
                state.users.splice(userIndex, 1, user);
            }
        },

        deleteUser(state: UserState, userId: string) {
            const userIndex = state.users.findIndex(user => user.id == userId);
            if(userIndex > -1){
                state.users.splice(userIndex, 1);
            }
         },
     },
     actions: {
         fetchUsers(context: UserContext) {
            return new Promise((resolve, reject) => {
                initServer(SERVER)
                const request = SERVER.get('admin/users')
                
                request.then((response) => {
                    const apiResponse= response.data;
                    if(apiResponse.success){
                        context.commit('setUsers', apiResponse.data);
                        resolve('done')
                    } else {
                        reject()
                    }
                }).catch((err) => {
                    reject()
                })
            });
         },

         createUser(context: UserContext, payload: any) {
            return new Promise((resolve, reject) => {
                initServer(SERVER)
                const request = SERVER.post('admin/users', payload)
                
                request.then((response) => {
                    const apiResponse= response.data;
                    if(apiResponse.success){
                        context.commit('appendUser', apiResponse.data);
                        resolve('done')
                    } else{
                        reject()
                    }
                }).catch((err) => {
                    reject()
                })
            });
         },

         updateUser(context: UserContext, payload: any) {
            return new Promise((resolve, reject) => {
                initServer(SERVER)
                const request = SERVER.put('/wl/user', payload)
                
                request.then((response) => {
                    const apiResponse= response.data;
                    if(apiResponse.success) {
                        context.commit('updateUser', apiResponse.data);
                        resolve('done')
                    } else {
                        reject(apiResponse.message)
                    }
                    
                }).catch((err) => {
                    reject(err)
                }) 
            });
         },

         deleteUser(context: UserContext, payload: any) {
             return new Promise((resolve, reject) => {
                 initServer(SERVER)
                 const request = SERVER.post('admin/delete-user', payload)
                 
                 request.then((response) => {
                     const apiResponse= response.data;
                     if(apiResponse.success) {
                        context.commit('deleteUser', payload.userId);
                        resolve('done')
                     } else {
                        reject(apiResponse.message)
                     }
                 }).catch((err) => {
                     
                 })
             });
         },

         resetPassword(context: UserContext, payload: any) {
            return new Promise((resolve, reject) => {
                initServer(SERVER)
                const request = SERVER.get(`admin/reset-user-password/${payload.email}`)
                request.then((response) => {
                    const apiResponse= response.data;
                    if(apiResponse.success) {
                       resolve('done')
                    } else {
                       reject(apiResponse.message)
                    }
                }).catch((err) => {
                    reject(err)
                })
            });
         }
     },
}

const { read, dispatch } = getStoreAccessors<UserState, ApplicationState>('user');

const getters = userState.getters;
const actions = userState.actions;

export const getUser = read(getters.getUser);
export const getWhiteLabelUsers = read(getters.getWhiteLabelUsers);

export const fetchUsers = dispatch(actions.fetchUsers);
export const createUser = dispatch(actions.createUser);
export const deleteUser = dispatch(actions.deleteUser);
export const updateUser = dispatch(actions.updateUser);
export const resetPassword = dispatch(actions.resetPassword);