






















































import { Modal } from "bootstrap";
import { Vue, Component } from "vue-property-decorator";
import { createUser } from "@/store/userState";
import { userRoles } from '@/utils/userRoles';

@Component
export default class NewUserModal extends Vue {
  firstName = '';
  lastName = '';
  email = '';
  password = '';
  userRole = '';

  get userRoles() {
    return userRoles;
  }

  create() {
    if(this.validate()) {
      const payload = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password,
        isActive: true,
        userRoles: this.userRole
      }

      createUser(this.$store, payload).then(() => {
        this.$toast.success('User created successfully')
        Modal.getInstance(document.getElementById('newUserModal')!)!.hide();
      }).catch(err => {
        this.$toast.error('Something went wrong. Try again');
      })
    }
  }

  validate() {
    if(this.firstName.length == 0){
      this.$toast.error('Enter first name');
      return false;
    }

    if(this.email.length == 0){
      this.$toast.error('Enter email');
      return false;
    }

    if(this.password.length == 0){
      this.$toast.error('Enter password');
      return false;
    }

     if(this.password.length < 8){
      this.$toast.error('Password should have atleast 8 characters');
      return false;
    }

    return true;
  }
}
