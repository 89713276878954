import { ApplicationState } from "./";
import { ActionContext } from "vuex";
import { getStoreAccessors } from "vuex-typescript";
import { initServer, SERVER } from "@/utils/server";

type AuthContext = ActionContext<AuthState, ApplicationState>

const STR_USER = "qrdad-admin"
const STR_SPAce = "qrdad-ace"

export interface AuthState {
    activeUser: any;
    workspaces: any[];
    currentWorkspaceId: string | null;
    isRegistering: boolean;
    isLoggingIn: boolean,
    folders: any[],
    selectedFolder: any
}

const initialState: AuthState = {
    activeUser: {},
    workspaces: [],
    currentWorkspaceId: null,
    isRegistering: false,
    isLoggingIn: false,
    folders: [],
    selectedFolder: {}
}

export interface registerCredentials {
    username?: string,
    email: string,
    password: string
}

export const authState = {
    namespaced: true,
    state: initialState,
    getters: {
        getCurrentWorkspaceId(state: AuthState) {
            return state.currentWorkspaceId;
        },

        getWorkspaces(state: AuthState) {
            return state.workspaces;
        },

        getIsLogginIn(state: AuthState) {
            return state.isLoggingIn;
        },

        getIsRegistering(state: AuthState) {
            return state.isRegistering
        },

        getActiveUser(state: AuthState) {
            return state.activeUser;
        },

        getFolders(state: AuthState, payload: any) {
            const currentWorkspace = state.workspaces.find(workspace => workspace.id == state.currentWorkspaceId);
            return currentWorkspace.folder;
        },

        getSelectedFolder(state: AuthState, payload: any) {
            return state.selectedFolder;
        }
    },

    mutations: {
        setIsRegistering(state: AuthState, payload: boolean) {
            state.isRegistering = payload;
        },

        setIsLoggingIn(state: AuthState, payload: boolean) {
            state.isLoggingIn = payload;
        },

        setActiveUser(state: AuthState, payload: any) {
            state.activeUser = payload;
        },

        setUserWorkspaces(state: AuthState, payload: any[]) {
            state.workspaces = payload;
        },

        setCurrentWorkspaceId(state: AuthState, workspaceId: string) {
            state.currentWorkspaceId = workspaceId;
        },

        appendWorkspace(state: AuthState, payload: any) {
            state.workspaces.push(payload);
        },

        setSelectedFolder(state: AuthState, payload: any) {
            state.selectedFolder = payload;
        }
    },

    actions: {
        setCurrentWorksapceId(context: AuthContext, payload: string) {
            return new Promise<void>((resolve, reject) => {
                context.commit('setCurrentWorkspaceId', payload);
                localStorage.setItem(STR_SPAce, JSON.stringify({ ace: payload, ser:  context.state.activeUser.id}))
                resolve()
            })
        },

        register({ commit }: AuthContext, payload: registerCredentials) {
            commit('setIsRegistering', true)
            return new Promise((resolve, reject) => {
                SERVER.post('auth/register', payload)
                    .then(response => {
                        resolve(response.data)
                        commit('setIsRegistering', false)
                    }).catch(err => {
                        reject(err);
                        commit('setIsRegistering', false)
                    })
            })
        },

        login({ commit }: AuthContext, payload: any) {
            commit('setIsLoggingIn', true)
            return new Promise((resolve, reject) => {
                SERVER.post('admin/login', payload)
                    .then(response => {
                        const apiResponse = response.data;
                        if (apiResponse.success) {
                            console.log(apiResponse);
                            const responseContent = apiResponse.data;
                            localStorage.setItem(STR_USER, JSON.stringify(responseContent));
                            commit('setActiveUser', responseContent.user);
                            // commit('setUserWorkspaces', responseContent.userWorkspaces);
                            // const currentWorkspaceInfo = localStorage.getItem(STR_SPAce);
                            // if (currentWorkspaceInfo != null) {
                            //     const workspaceContent = JSON.parse(currentWorkspaceInfo);
                            //     if(workspaceContent.ser == responseContent.user.id){
                            //         commit('setCurrentWorkspaceId', workspaceContent.ace)
                            //     } else {
                            //         if (responseContent.userWorkspaces.length > 0) {
                            //             commit('setCurrentWorkspaceId', responseContent.userWorkspaces[0].workspace.id);
                            //             localStorage.setItem(STR_SPAce, JSON.stringify({ace: responseContent.userWorkspaces[0].workspace.id, ser: responseContent.user.id}))
                            //         }
                            //     }
                            // } else {
                            //     if (responseContent.userWorkspaces.length > 0) {
                            //         commit('setCurrentWorkspaceId', responseContent.userWorkspaces[0].workspace.id);
                            //         localStorage.setItem(STR_SPAce, JSON.stringify({ace: responseContent.userWorkspaces[0].workspace.id, ser: responseContent.user.id}))
                            //     }
                            // }
                            resolve(apiResponse)
                        } else {
                            reject(apiResponse.message)
                        }
                        commit('setIsLoggingIn', false)
                    }).catch(err => {
                        console.log(err);
                        reject(err);
                        commit('setIsLoggingIn', false)
                    })
            });

        },

        attemptLoginFromLocalStorage({ commit }: AuthContext) {
            const userToken = JSON.parse(localStorage.getItem(STR_USER) as string);
            const tokenExist = userToken != null;

            if (tokenExist) {
                commit('setActiveUser', { ...userToken.user });
                // commit('setUserWorkspaces', userToken.userWorkspaces);

                // const currentWorkspaceInfo = localStorage.getItem(STR_SPAce);
                // if (currentWorkspaceInfo != null) {
                //     const workspaceContent = JSON.parse(currentWorkspaceInfo);
                //     if(workspaceContent.ser == userToken.user.id){
                //         commit('setCurrentWorkspaceId', workspaceContent.ace)
                //     } else {
                //         if (userToken.userWorkspaces.length > 0) {
                //             commit('setCurrentWorkspaceId', userToken.userWorkspaces[0].workspace.id);
                //             localStorage.setItem(STR_SPAce, JSON.stringify({ace: userToken.userWorkspaces[0].workspace.id, ser: userToken.user.id}))
                //         }
                //     }
                // } else {
                //     if (userToken.userWorkspaces.length > 0) {
                //         commit('setCurrentWorkspaceId', userToken.userWorkspaces[0].workspace.id);
                //         localStorage.setItem(STR_SPAce, JSON.stringify({ace: userToken.userWorkspaces[0].workspace.id, ser: userToken.user.id}))
                //     }

                // }
                return new Promise((resolve, reject) => {
                    resolve(userToken);
                });
            }

            return undefined;
        },

        logOut({ commit }: AuthContext) {
            localStorage.removeItem(STR_USER);
            localStorage.removeItem(STR_SPAce);
            return Promise.resolve();
        },

        newWorkspace({ commit }: AuthContext, payload: any) {
            return new Promise((resolve, reject) => {
                const request = SERVER.post('workspace/create', payload);

                request.then(response => {
                    const apiResponse = response.data;
                    if (!apiResponse.error) {
                        const workspaceData = apiResponse.data;
                        // commit('setUserWorkspaces', workspaceData);
                        resolve(workspaceData);
                    } else {
                        reject('Something went wrong')
                    }
                }).then(err => {
                    reject(err);
                })
            });
        },

        fetchWorkspaces({ commit }: AuthContext) {
            return new Promise((resolve, reject) => {
                initServer(SERVER);
                const request = SERVER.get(`workspace/user`);

                request.then(response => {
                    const apiResponse = response.data;
                    if (!apiResponse.error) {
                        const workspaceData = apiResponse.data;
                        commit('setUserWorkspaces', workspaceData);
                        const currentWorkspaceInfo = localStorage.getItem(STR_SPAce);
                        const userToken = JSON.parse(localStorage.getItem(STR_USER) as string);
                        if (currentWorkspaceInfo != null) {
                            const workspaceContent = JSON.parse(currentWorkspaceInfo);
                            if(workspaceContent.ser == userToken.user.id){
                                commit('setCurrentWorkspaceId', workspaceContent.ace)
                            } else {
                                if (workspaceData.length > 0) {
                                    commit('setCurrentWorkspaceId', workspaceData[0].id)
                                    localStorage.setItem(STR_SPAce, workspaceData[0].id)
                                }
                            }
                        } else {
                            if (workspaceData.length > 0) {
                                commit('setCurrentWorkspaceId', workspaceData[0].id)
                                localStorage.setItem(STR_SPAce, workspaceData[0].id)
                            }
                        }

                        resolve(workspaceData)
                    } else {
                        reject('Something went wrong')
                    }
                }).catch(err => {
                    reject(err);
                })
            });
        },

        createFolder({ commit }: AuthContext, payload: any) {
            return new Promise((resolve, reject) => {
                initServer(SERVER);
                const request = SERVER.post(`folder/create`, payload);

                request.then(response => {
                    const apiResponse = response.data;
                    if (!apiResponse.error) {
                        SERVER.get(`workspace/user`).then(response => {
                            const apiResponse = response.data;
                            if (!apiResponse.error) {
                                const workspaceData = apiResponse.data;
                                commit('setUserWorkspaces', workspaceData);
                                if (workspaceData.length > 0) {
                                    commit('setCurrentWorkspaceId', workspaceData[0].id)
                                }
                                resolve(workspaceData)
                            } else {
                                reject('Something went wrong')
                            }
                        }).catch(err => {
                            reject(err);
                        })
                    } else {
                        reject('Something went wrong')
                    }
                }).catch(err => {
                    reject(err);
                })
            })
        },

        setSelectedFolder(context: AuthContext, payload: any) {
            context.commit('setSelectedFolder', payload);
        },

         updateProfile(context: AuthContext, payload: any) {
            return new Promise((resolve, reject) => {
                initServer(SERVER)
                const request = SERVER.put('/users', payload);

                request.then(response => {
                    const apiResponse = response.data;
                    // context.commit('setActiveUser', apiResponse.user);
                    resolve(apiResponse)
                }).catch(err => {
                    reject(err);
                })
            });
        },

        updatePassword(context: AuthContext, payload: any) {
            return new Promise((resolve, reject) => {
                initServer(SERVER)
                const request = SERVER.put('/users/password', payload);

                request.then(response => {
                    const apiResponse = response.data;
                    if(apiResponse.success){
                        resolve(apiResponse)
                    } else {
                        reject(apiResponse.message)
                    }
                }).catch(err => {
                    reject(err);
                })
            });
        },

    }
}

const { read, dispatch } = getStoreAccessors<AuthState, ApplicationState>('auth');

const getters = authState.getters;
const actions = authState.actions;

export const getCurrentWorkspaceId = read(getters.getCurrentWorkspaceId);
export const getWorkspaces = read(getters.getWorkspaces);
export const getActiveUser = read(getters.getActiveUser);
export const getIsRegistering = read(getters.getIsRegistering);
export const getIsLogginIn = read(getters.getIsLogginIn);
export const getFolder = read(getters.getFolders);
export const getSelectedFolder = read(getters.getSelectedFolder);

export const setCurrentWorksapceId = dispatch(actions.setCurrentWorksapceId);
export const login = dispatch(actions.login);
export const register = dispatch(actions.register);
export const attemptLoginFromLocalStorage = dispatch(actions.attemptLoginFromLocalStorage);
export const logOut = dispatch(actions.logOut);
export const newWorkspace = dispatch(actions.newWorkspace);
export const fetchWorkspaces = dispatch(actions.fetchWorkspaces);
export const createFolder = dispatch(actions.createFolder);
export const setSelectedFolder = dispatch(actions.setSelectedFolder);
export const updateProfile = dispatch(actions.updateProfile);
export const updatePassword = dispatch(actions.updatePassword)